






import { defineComponent } from '~/utils/nuxt3-migration'

import CLogin from '~/components/shared/login/CLogin.vue'

export default defineComponent({
  components: {
    CLogin
  },
  middleware: 'already-logged-in',
  setup() {
    function handleSuccessLogin(redirectUlr: string) {
      window.location = redirectUlr
    }

    return { handleSuccessLogin }
  },
  head() {
    return {
      title: this.$t('login')
    }
  }
})
