
















import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    error: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    size: {
      type: String as PropType<'sm' | 'md'>,
      default: 'md'
    },
    alt: {
      type: Boolean,
      required: false,
      default: false
    },
    center: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
